const hasItemSelected = (selected, rows) => selected.length && rows.length

const disputeAllowedDistributionChannels =
  process.env.REACT_APP_DISPUTE_ALLOWED_DISTRIBUTION_CHANNELS.split(',').map(
    (channel) => parseInt(channel)
  )

export default (rows, selected) => {
  let hasCredit = false
  let hasSomeDispute = false
  let hasNotAllowedDistributionChannel = false

  if (hasItemSelected(selected, rows)) {
    rows.forEach((invoice) => {
      if (selected.includes(invoice.id)) {
        if (invoice.isDispute) return (hasSomeDispute = true)
        if (Number(invoice.totalAmount) < 0) return (hasCredit = true)

        if (
          !disputeAllowedDistributionChannels.includes(
            invoice.distributionChannel
          )
        )
          return (hasNotAllowedDistributionChannel = true)
      }
    })
  }

  return (
    !hasCredit &&
    !hasSomeDispute &&
    !hasNotAllowedDistributionChannel
  )
}
